'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { AbsoluteCenter, Box, Button, Flex, Text } from '@chakra-ui/react';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const t = useTranslations();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <Flex
      minH='100vh'
      w='full'
    >
      <AbsoluteCenter>
        <Text
          textStyle='heading-large-4xl'
          mb='20px'
          textAlign='center'
        >
          500
        </Text>
        <Box textAlign='center'>
          <Text
            textStyle='heading-large-lg'
            mb='20px'
          >
            {t('NotFound.internalServerError')}
          </Text>
          <Text
            textStyle='heading-regular-md'
            color='gray'
            mb='20px'
          >
            {t('NotFound.internalServerErrorHelpText')}
          </Text>
        </Box>
        <Box textAlign='center'>
          <Button
            variant='outline'
            onClick={reset}
          >
            {t('NotFound.retry')}
          </Button>
        </Box>
      </AbsoluteCenter>
    </Flex>
  );
}
